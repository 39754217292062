import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import InstagramFeed from './InstagramFeed'
import MiscChildLinks from './MiscChildLinks'
import SearchResults from './SearchResults'

export default function MiscShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    instagram: InstagramFeed,
    childlinks: MiscChildLinks,
    searchresults: SearchResults
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
